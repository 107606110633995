// Language suggestion bar

.language-message {
    color: $color-white;
    background: $color-blue-light;

    &__languages {
        .btn {
            border-color: $color-white;
        }
    }
}

// Language popup
.popup {
    position: fixed;
    display: none;
    z-index: 99999;

    &.active {
        display: block;
    }

    &::before {
        position: fixed;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        content: '';
        opacity: .9;
        background: $color-white;
    }

    &__wrapper {
        position: fixed;
        top: 50%;
        left: 50%;
        display: block;
        width: 700px;
        max-width: 80%;
        color: $color-white;
        padding: 30px 40px 10px;
        transform: translate(-50%, -50%);
        background-color: $color-blue-dark;
        border: 0 solid;
        text-align: center;
        max-height: 90%;
        overflow: auto;

        @include media-breakpoint-down(sm) {
            padding: 50px 20px;
        }

        h2 {
            text-align: center;
            line-height: 38px;
            font-size: 28px;
            text-transform: capitalize;
            color: $color-white;

            @include media-breakpoint-down(sm) {
                padding: 0;
            }
        }

        .btn {
            &--close {
                position: absolute;
                top: 10px;
                right: 10px;
                text-transform: capitalize;

                &:hover,
                &:focus {
                    color: $color-blue-light;
                    background: transparent;

                    &::after {
                        @include icon(close, $color-blue-light, 20px, 20px);
                    }
                }
            }
        }
    }
}

// Search
.search-results {
    &--list {
        .url {
            color: $text-color;
        }
    }
}

.search-result {
    &-item {
        color: $text-color;
        margin-bottom: $default-margin;
        border-bottom: 1px solid rgba($text-color, .2);

        &:hover,
        &:focus {
            color: $color-blue-light;
            text-decoration: none;
        }

        &__image {
            display: none;
        }
    }
}
