.vcard {
    width: 325px;
    max-width: 100%;
    margin: 30px auto 0;

    &__wrapper {
        border: 2px solid $color-blue;
        padding: 20px 20px 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-bottom: 0;
    }

    &__logo {
        @include make-col(12);
        text-align: center;
        margin-bottom: 30px;
    }

    &__title {
        flex: 1;
        margin-bottom: 30px;

        .is-h1 {
            font-size: 16px;
            line-height: 19px;
            color: $color-blue-dark;

            + .is-h2 {
                margin-top: -25px;
                margin-bottom: 0;
            }
        }

        .is-h2 {
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            color: $color-blue-light;
        }
    }

    &__image {
        flex: 0 0 80px;
        max-width: 80px;
        margin-bottom: 30px;
        position: relative;

        img {
            border-radius: 50%;
        }

        .cta {
            position: absolute;
            width: 30px;
            height: 30px;
            left: 50%;
            bottom: -15px;
            transform: translateX(-50%);
            border-radius: 50%;
            background: $color-white;

            .icon {
                &--linkedin {
                    @include icon(linkedin, $color-blue-light, 12px, 12px);
                    width: 30px;
                    height: 30px;
                }
            }

            &:hover,
            &:focus {
                background: $color-blue-light;

                .icon {
                    &--linkedin {
                        @include icon(linkedin, $color-white, 12px, 12px);
                    }
                }
            }
        }
    }

    &__content {
        @include make-col(12);
        margin-bottom: 30px;

        .list {
            margin-bottom: 20px;
        }

        .brand-line {
            max-width: 75%;
            margin: 0 auto;
            display: block;
        }
    }

    &__actions {
        display: flex;
        flex-wrap: wrap;

        .social-share {
            @include make-col(6);

            .btn:not(.btn--close) {
                width: 100%;
                max-width: 100%;
            }
        }

        .btn {
            @include make-col(6);
            text-transform: none;
            font-size: 16px;
            line-height: normal;
            padding: 16px 20px;
            text-align: left;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            border: 2px solid $color-blue-light;

            &:hover,
            &:focus {
                background: lighten($color-blue-light, 10%);
                border-color: lighten($color-blue-light, 10%);
            }

            &--secondary {
                border: 2px solid $color-blue-dark;

                &:hover,
                &:focus {
                    background: darken($color-blue-dark, 10%);
                    border-color: darken($color-blue-dark, 10%);
                }
            }

            + .btn {
                margin-left: 0;
            }

            span {
                margin: 0 auto;
            }

            .icon {
                &--share {
                    background-size: 19px;
                }
            }

            &--close {
                display: block;
                font-size: 0;
                padding: 0;

                &:hover,
                &:focus {
                    @include icon(close, $color-black, 20px, 20px);
                    background-color: transparent;
                }
            }
        }
    }
}
