.block {
    &--job-alert {
        margin-bottom: $default-block-margin;
    }
}

.job-alert {
    &__form {
        .form {
            flex-direction: row;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
        }

        .form-group {
            @include make-col(2.4);
            padding: 0 10px;

            @include media-breakpoint-down(lg) {
                @include make-col(6);
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
                padding: 0;
            }

            &:last-of-type {
                @include make-col(12);
                text-align: center;

                @include media-breakpoint-down(sm) {
                    .btn {
                        width: 100%;
                    }
                }
            }
        }

        .form-control {
            padding: .8rem 0;
        }
    }
}
