.header {
    background: $color-white;

    &__search {
        background-color: $color-blue-light;

        .form-search {
            margin: 0;

            .form-control {
                border-color: $color-white;
                color: $color-white;
            }

            .btn {
                @include icon(search, $color-white, 20px, 20px);

                &:hover,
                &:focus {
                    @include icon(search, $color-blue-dark, 20px, 20px);
                    background-color: transparent;
                    border: 0;
                }
            }
        }

        .search-suggestions {
            background-color: $color-black;
            border: 1px solid $color-white;

            &::after {
                border-top: 1px solid $color-white;
                border-left: 1px solid $color-white;
                background-color: $color-black;
            }

            li {
                border-bottom: 1px solid $color-white;

                a {
                    color: $color-white;
                }
            }
        }
    }

    .logo {
        height: 40px;

        @include media-breakpoint-down(md) {
            height: 32px;
        }
    }

    .nav-toggle {
        @include icon(close, transparent, 20px, 20px);

        span,
        &::before,
        &::after {
            background-color: $color-blue-light;
        }

        &:hover,
        &:focus {
            span,
            &::before,
            &::after {
                background-color: $color-blue-dark;
            }
        }

        &.active {
            @include icon(close, $color-blue-dark, 20px, 20px);

            &:hover,
            &:focus {
                @include icon(close, $color-blue-light, 20px, 20px);
            }
        }
    }

    .search-toggle {
        @include icon(search, $color-blue-dark, 20px, 20px);

        &:hover,
        &:focus {
            @include icon(search, $color-blue-light, 20px, 20px);
        }

        &.active {
            @include icon(close, $color-blue-dark, 20px, 20px);

            &:hover,
            &:focus {
                @include icon(close, $color-blue-light, 20px, 20px);
            }
        }
    }

    .language-toggle {
        @include icon(globe, $color-blue-dark, 20px, 20px);

        &:hover,
        &:focus {
            @include icon(globe, $color-blue-light, 20px, 20px);
        }
    }

    .contact-toggle {
        @include icon(contact, $color-blue-dark, 20px, 20px);
        order: 4;

        &:hover,
        &:focus {
            @include icon(contact, $color-blue-light, 20px, 20px);
        }
    }

    .downloads-toggle {
        @include icon(downloads, $color-blue-dark, 20px, 20px);
        order: 3;

        &:hover,
        &:focus {
            @include icon(downloads, $color-blue-light, 20px, 20px);
        }
    }
}
