.social-share {
    $self: &;

    &--wrapper {
        background-color: $color-blue-light;
    }

    h2 {
        color: $color-white;
        background-color: $color-blue-light;

        &::before {
            @include icon(share, $color-white, 20px, 20px);
        }
    }

    input {
        color: $color-white;
        background-color: $color-blue-light;
        border: 2px solid $color-white;
    }

    .btn {
        &--close {
            @include icon(close, $color-white, 20px, 20px);

            &:hover,
            &:focus {
                @include icon(close, $color-black, 20px, 20px);
                background-color: transparent;
            }
        }
    }
}
