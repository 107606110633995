.swiper {
    .swiper-navigation {
        .swiper-button-next,
        .swiper-button-prev {
            @include icon(arrow, $color-blue-light, 10px, 20px);
        }
    }

    .swiper-pagination {
        .swiper-pagination-bullet {
            border-color: $color-blue-light;

            &.swiper-pagination-bullet-active {
                background-color: $color-blue-light;
            }
        }
    }
}
