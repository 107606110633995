.richtext {
    a:not(.btn) {
        color: $color-blue-light;
        outline: none;

        &:hover,
        &:focus {
            color: $color-blue-dark;
            outline: none;
        }
    }

    &.center {
        text-align: center;
    }
}

.sales {
    .richtext {
        p {
            line-height: 32px;
        }
    }
}
