.block {
    &--planning-form {
        .planning-title,
        .richtext {
            text-align: center;
        }

        .form {
            .form-row {
                margin-top: 20px;
            }

            .btn {
                margin-top: 20px;
            }
        }

        .success-message {
            font-weight: bold;
            font-style: italic;
        }
    }
  }