@import '../general/style';

@import 'settings/vars';

@import 'libs/swiper/swiper';
@import 'bootstrap-multiselect/dist/css/bootstrap-multiselect.css';

@import 'base/base';
@import 'base/heading';
@import 'base/list';
@import 'layout/header';
@import 'layout/footer';
@import 'components/richtext';
@import 'components/block';
@import 'components/breadcrumb';
@import 'components/button';
@import 'components/form';
@import 'components/navigation';
@import 'components/multiselect';
@import 'components/social';
@import 'components/vcard';
@import 'components/sprays';

// Start of scss contentblocks
@import 'contentblocks/anchor-block';
@import 'contentblocks/content-block';
@import 'contentblocks/hero-block';
@import 'contentblocks/highlight-block';
@import 'contentblocks/faq-block';
@import 'contentblocks/howto-block';
@import 'contentblocks/usps-block';
@import 'contentblocks/testimonial-block';
@import 'contentblocks/planning-block';

@import 'contentblocks/jobs';
@import 'contentblocks/job-alert';
@import 'contentblocks/job-apply';
@import 'contentblocks/job';

@import 'components/bg-colors';
