.breadcrumb {
    &-item {
        color: $color-blue-dark;

        a {
            color: $color-blue-dark;
        }

        &.active {
            a {
                color: $color-blue-dark;
                text-decoration: none;
            }
        }

        + .breadcrumb {
            &-item {
                &::before {
                    color: $color-blue-dark;
                }
            }
        }
    }
}
