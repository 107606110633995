// Content block
.block {
    &--content-block {
        + .block {
            &--job-alert,
            &--jobs {
                margin-top: -($default-block-margin / 2);
            }
        }

        &--blue {
            background-color: $color-blue-dark;

            padding-top: 80px;
            color: $color-white;
            text-align: center;

            .is-h2 {
                color: $color-white;
            }
        }

        &--video {
            background-color: $color-gray-blue;

            .video {
                display: flex;
                justify-content: center;
                max-height: 400px;
                margin-top: -80px;
                margin-bottom: 40px;

                &__content {
                    display: flex;
                    justify-content: center;    
                }

                video {
                    max-height: 100%;
                }
            }
        }
    }
}

.content-block {
    width: calc(80% + 30px);

    @include media-breakpoint-down(md) {
        width: auto;
    }
}