// Highlights block

.highlight {
    $self: &;

    &--hero {
        #{ $self }__image {
            @include make-col(6);

            @include media-breakpoint-down(md) {
                @include make-col(6);
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }

            &::after {
                display: none;
            }
        }

        #{ $self }__content {
            @include make-col(6);
            padding: 30px 30px 0;
            background: $color-blue-dark;

            @include media-breakpoint-down(md) {
                @include make-col(6);
                padding: 30px 30px 0;
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
                padding: 30px 30px 0;
            }

            .is-h2, h2.is-h4 {
                color: $color-white;
            }
        }

        &#{ $self }--reversed {
            #{ $self }__content {
                padding: 30px 30px 0;
            }
        }
    }

    &--small {
        #{ $self }__content {
            .is-h4 {
                color: $color-white;
            }
        }

        &[href] {
            &[target] {
                #{ $self }__content {
                    .is-h4 {
                        &::after {
                            @include icon(link, $color-white, 14px, 14px);
                        }
                    }
                }
            }
        }
    }

    &--row {
        background-color: $color-blue-dark;

        #{ $self }__content {
            .is-h3 {
                a {
                    color: $color-white;

                    &:hover,
                    &:focus {
                        color: $color-blue-light;
                    }
                }
            }
        }
    }

    &--red {
        #{ $self }__image {
            &::after {
                background: $color-red;
            }
        }

        #{ $self }__content {
            background: $color-red;
        }
    }

    &--green {
        #{ $self }__image {
            &::after {
                background: $color-green;
            }
        }

        #{ $self }__content {
            background: $color-green;
        }
    }

    &--blue {
        #{ $self }__image {
            &::after {
                background: $color-blue-light;
            }
        }

        #{ $self }__content {
            background: $color-blue-light;
        }
    }

    &--yellow {
        #{ $self }__image {
            &::after {
                background: $color-yellow;
            }
        }

        #{ $self }__content {
            background: $color-yellow;
        }
    }

    &--orange {
        #{ $self }__image {
            &::after {
                background: $color-orange;
            }
        }

        #{ $self }__content {
            background: $color-orange;
        }
    }
}


.highlight--small .highlight__content h2.is-h4 {
    color: #fff;
}
