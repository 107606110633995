.form-group {
    &.has-error {
        .form-control {
            border-color: $color-red;
        }

        .help-block {
            color: $color-red;
        }
    }
}

.form-control {
    color: $color-blue-dark;
    font-family: $text-font;
    font-size: $text-size;
    line-height: $line-height;
    padding: 3px 0;
    border: 0 solid $color-blue-dark;
    border-bottom: 2px solid $color-blue-dark;
    background-color: transparent;

    &:focus {
        color: $color-blue-dark;
        border-color: $color-blue-dark;
        background-color: transparent;
    }

    &--big {
        font-weight: 600;
        text-transform: none;
        padding: 13px 0;

        @include media-breakpoint-down(md) {
            padding: 13px 0;
        }
    }

    &--select {
        @include icon(select, $color-blue-dark, 10px, 15px);
        background-position: center right 10px;
        background-color: transparent;
        background-size: 15px;
    }

    &--check {
        input[type="radio"],
        input[type="checkbox"] {
            + i {
                border-color: $color-white;

                &::before {
                    background-color: $color-white;
                }
            }
        }
    }

    &::-webkit-input-placeholder {
        color: $color-blue-dark;
        font-style: normal;
        opacity: 1;
    }

    &::-moz-placeholder {
        color: $color-blue-dark;
        font-style: normal;
        opacity: 1;
    }

    &:-ms-input-placeholder {
        color: $color-blue-dark;
        font-style: normal;
        opacity: 1;
    }
}

.privacy_statement_jobs {
    float: left;
    margin-top: 8px;
    margin-right: 8px;
}

.form-inline {
    .form-group {
        &--error {
            .help-block {
                color: $color-red;
            }
        }
    }
}

.form-search {
    position: relative;

    .form-control {
        border-color: $color-blue;
        color: $text-color;

        &::-webkit-input-placeholder {
            color: $color-white;
            font-style: normal;
            opacity: 1;
        }

        &::-moz-placeholder {
            color: $color-white;
            font-style: normal;
            opacity: 1;
        }

        &:-ms-input-placeholder {
            color: $color-white;
            font-style: normal;
            opacity: 1;
        }
    }

    .btn {
        @include icon(search, $color-blue, 20px, 20px);

        &:hover,
        &:focus {
            @include icon(search, $color-blue-dark, 20px, 20px);
            background-color: transparent;
            border: 0;
        }
    }
}

::-webkit-input-placeholder {
    color: $color-blue-dark;
    font-style: normal;
    opacity: 1;
}

::-moz-placeholder {
    color: $color-blue-dark;
    font-style: normal;
    opacity: 1;
}

:-ms-input-placeholder {
    color: $color-blue-dark;
    font-style: normal;
    opacity: 1;
}

.spotler-form {
    .mpForm {
        .mpQuestionTable {
            input[type="radio"],
            input[type="checkbox"] {

                + label {
                    &::before {
                        background-color: $color-blue-dark;
                    }

                    &::after {
                        border: 2px solid $color-blue-dark;
                    }
                }
            }
        }
    }
}

.popup {
    &--countries {
        .form-control {
            border-bottom: 2px solid $color-white;
            color: $color-white;
            font-family: $text-font;
            font-size: $text-size;
            line-height: $line-height;
            padding: 3px 0;

            &--select {
                @include icon(select, $color-white, 10px, 15px);
                background-position: center right 10px;
                background-color: transparent;
                background-size: 15px;
            }
        }
    }
}

