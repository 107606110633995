// FAQ block
.block {
    &--faq {
        margin: 0 0 $default-block-margin;
        padding: 70px 0 10px;
        background: $color-white;
    }

    &--faq + &--faq {
        margin-top: -$default-block-margin;
    }
}

.faq {
    $self: &;
    display: flex;
    flex-flow: wrap row;
    justify-content: center;
    margin-bottom: $default-block-margin - $default-margin;
    background: $color-white;

    &__title {
        @include make-col(8);
        padding-left: 15px;
        padding-right: 15px;

        @include media-breakpoint-down(md) {
            @include make-col(12);
            padding-left: 10px;
            padding-right: 10px;
        }

        + #{ $self }__content {
            margin-top: -($default-margin / 2);
        }
    }

    &__header {
        margin-bottom: 75px;
        
        .faq-group-link {
            background-color: #f0f0f0;
            border-radius: 20px;
            color: #5d6770;
            font-size: 16px;
            font-weight: 600;
            line-height: 26px;
            padding: 7px 20px;
            text-decoration: none;
            text-transform: lowercase;
            margin: 0 5px 0 5px;

            &:hover {
                background-color: #c4c4c4;
            }
        }
    }

    &__content {
        @include make-col(8);
        padding-left: 15px;
        padding-right: 15px;

        @include media-breakpoint-down(md) {
            @include make-col(12);
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    &__buttons {
        @include make-col(8);
        padding-left: 15px;
        padding-right: 15px;

        @include media-breakpoint-down(md) {
            @include make-col(12);
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    &--group {
        #{ $self }__title {
            @include make-col(12);
            width: 100%;
            text-align: center;
        }

        #{ $self }__content {
            @include make-col(12);
            width: 100%;
            padding: 0;
            margin: 0 -30px;
            display: flex;
            flex-flow: row wrap;
        }

        #{ $self }__buttons {
            @include make-col(12);
            width: 100%;
            text-align: center;
        }
    }
}

.faq-overview {
    display: flex;
    flex-wrap: wrap;

    &__item {
        @include make-col(12);
        display: flex;
        flex-direction: column;
        
        &:nth-child(n+4) {
            display: none;
        }

        &:last-of-type {
            .faq-question {
                &__title {
                    border-bottom: 0 solid;
                }
            }
        }
    }

    &.active {
        .faq-overview__item {
            &:nth-child(n+4) {
                display: block;
            }
        }
    }
    
    .show-all {
        margin-top: 20px;
        font-size: 16px;
        padding: 7px 25px;
    }
}

.faq-group {
    @include make-col(6);
    padding-left: 35px;
    padding-right: 35px;

    @include media-breakpoint-down(md) {
        @include make-col(12);
    }

    .is-h4 {
        text-transform: uppercase;
        margin-bottom: 40px;
    }
}

.faq-question {
    $self: &;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    cursor: pointer;
    margin: 0 0 10px;

    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 35px;
        display: block;
        height: 1px;
        content: '';
        background-color: transparent;
    }

    &__title {
        position: relative;
        display: flex;
        margin: 0;
        padding: 0 40px 0 0;
        cursor: pointer;
        border-bottom: 1px solid rgba($color-gray-light, .2);

        &::after {
            @include icon(arrow, $color-blue-dark, 12px, 12px);
            position: absolute;
            top: -9px;
            right: -15px;
            display: block;
            width: 40px;
            height: 40px;
            content: '';
            transform: rotate(90deg);
            transition: all .3s;

            @include media-breakpoint-down(sm) {
                right: 0;
            }
        }

        .is-h5 {
            color: $text-color;
            font-weight: 400;
            text-transform: none;
            margin: 0;
        }
    }

    &__content {
        display: none;
        flex: 1;
        padding: 10px 0 0 45px;

        @include media-breakpoint-down(md) {
            padding: 0;
        }

        .richtext {
            margin: 0;
            font-size: 15px;
        }
    }

    &.active {
        #{ $self } {
            &__title {
                &::after {
                    @include icon(close, $color-blue-dark, 12px, 12px);
                }
            }

            &__content {
                display: block;
            }
        }
    }
}
