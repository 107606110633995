// USPS block

.usp {
    $self: &;
    color: $text-color;
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: .04px;

    &[href] {
        color: $text-color;
        text-decoration: none;

        &:hover,
        &:focus {
            color: $text-color;
            text-decoration: underline;
        }
    }
}
