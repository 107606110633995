// Testimonial block

.testimonial {
    &__image {
        @include make-col(6);

        @include media-breakpoint-down(md) {
            @include make-col(6);
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        &::after {
            display: none;
        }
    }

    &__content {
        @include make-col(6);
        padding: 30px 70px 0;
        background: $color-blue-light;

        @include media-breakpoint-down(md) {
            @include make-col(6);
            padding: 30px 30px 0;
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        .is-h2 {
            color: $color-white;
            font-size: 56px;
            line-height: 66px;
            font-weight: 300;

            @include media-breakpoint-down(md) {
                font-size: 42px;
                line-height: 52px;
            }

            @include media-breakpoint-down(sm) {
                font-size: 32px;
                line-height: 42px;
            }
        }
    }
}
