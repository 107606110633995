.block {
    &--job-apply {
        margin-bottom: $default-block-margin;
    }
}

.job-apply {
    @include make-col(8);
    margin: 0 auto;

    @include media-breakpoint-down(sm) {
        @include make-col(12);
    }
}
