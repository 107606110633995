.btn {
    $self: &;
    font-family: $text-font;
    color: $color-white;
    font-size: 17px;
    line-height: 18px;
    padding: 14px 25px;
    border-color: $color-blue-light;
    background-color: $color-blue-light;
    border-radius: 30px;

    + .btn {
        margin-left: 15px;
    }

    &:hover,
    &:focus {
        color: $color-white;
        border-color: $color-blue-dark;
        background-color: $color-blue-dark;
    }

    &--close {
        color: $color-white;
        border: 0;
        background: transparent;
        padding: 0;

        &::after {
            @include icon(close, $color-white, 20px, 20px);
        }

        &:hover,
        &:focus {
            color: $color-blue-dark;
            background: transparent;

            &::after {
                @include icon(close, $color-blue-dark, 20px, 20px);
            }
        }
    }

    &--vacancy {
        border-radius: 40px;
        font-size: 17px;
        text-transform: unset;
        font-weight: 400;
        padding: 7px 30px;
    }

    &--white {
        color: $color-blue-dark;
        padding: 8px 10px;
        background-color: $color-white;
        border-color: $color-white;

        &:hover,
        &:focus {
            color: $color-white;
            background-color: $color-blue-dark;
            border-color: $color-blue-dark;
        }
    }

    &--black {
        color: $color-white;
        background-color: $color-blue-dark;

        &:hover,
        &:focus {
            color: $color-white;
            background-color: $color-blue-light;
        }
    }

    &--link {
        color: $color-blue-light;
        font-size: $text-size;
        line-height: $line-height;
        font-weight: 400;
        padding: 0;
        border: 0;
        background-color: transparent;
        text-transform: none;

        &::after {
            display: none;
        }

        &:hover,
        &:focus {
            color: $color-blue-dark;
            background-color: transparent;
        }
    }

    &--secondary {
        background: $color-blue-dark;
        color: $color-white;

        &:hover,
        &:focus {
            background: lighten($color-blue-dark, 10%);
            color: $color-white;
        }
    }

    &--social-share {
        color: $color-white;
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        padding: 5px 0;
        text-transform: uppercase;
        background-color: transparent;
        border: 0;

        &::before {
            @include icon(share, $color-white, 16px, 20px);
        }

        &:hover,
        &:focus {
            color: $color-blue-light;
            text-decoration: none;
            background-color: transparent;

            &::before {
                @include icon(share, $color-blue-light, 16px, 20px);
            }
        }
    }
}
