h1,
.is-h1 {
    font-family: $heading-font;
    color: $color-blue-light;
    font-size: 56px;
    line-height: 56px;
    font-weight: 700;
    text-transform: none;
    letter-spacing: .20px;

    @include media-breakpoint-down(md) {
        font-size: 48px;
        line-height: 48px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 38px;
        line-height: 48px;
    }

    .special {
        font-family: 'Open Sans', sans-serif;
        color: $color-blue-light;
        font-size: 56px;
        font-weight: 700;
        
        @include media-breakpoint-down(sm) {
            color: $color-white;
        }  
    }
}

h2,
.is-h2 {
    font-family: $heading-font;
    color: $color-blue-light;
    font-size: 52px;
    line-height: 52px;
    font-weight: 700;
    text-transform: none;
    letter-spacing: .04px;

    @include media-breakpoint-down(md) {
        font-size: 42px;
        line-height: 42px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 32px;
        line-height: 32px;
    }

    .special {
        font-family: 'Open Sans', sans-serif;
        color: $color-blue-light;
        font-size: 52px;
        font-weight: 700;
    }
}

h3,
.is-h3 {
    font-family: $heading-font;
    color: $color-blue-dark;
    font-size: 42px;
    line-height: 42px;
    font-weight: 900;
    text-transform: none;
    letter-spacing: .04px;

    @include media-breakpoint-down(md) {
        font-size: 42px;
        line-height: 42px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 32px;
        line-height: 32px;
    }

    .special {
        font-family: 'Open Sans', sans-serif;
        color: $color-blue-light;
        font-size: 42px;
        font-weight: 700;
    }
}

h4,
.is-h4 {
    font-family: $heading-font;
    color: $color-blue-dark;
    font-size: 21px;
    line-height: 26px;
    font-weight: 700;
    text-transform: none;
    letter-spacing: .04px;
    margin: 0 0 $default-margin / 2;
}

h5,
.is-h5 {
    font-family: $heading-font;
    color: $color-blue-dark;
    font-size: 16px;
    line-height: 32px;
    font-weight: 700;
    text-transform: none;
    letter-spacing: .04px;
    margin: 0 0 $default-margin / 2;
}

h6,
.is-h6 {
    font-family: $heading-font;
    color: $color-blue-dark;
    font-size: 14px;
    line-height: 28px;
    font-weight: 700;
    margin: 0 0 $default-margin / 2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    + .info {
        font-size: 14px;
        margin-top: -$default-margin;
    }
}

.sales {
    h1,
    .is-h1,
    h2,
    .is-h2,
    h3,
    .is-h3,
    h4,
    .is-h4,
    h5,
    .is-h5,
    h6,
    .is-h6 {
        color: $color-blue-dark;

        span {
            color: $color-blue-light;
        }
    }
}
