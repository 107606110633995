.navigation {
    $self: &;

    ul {
        li {
            a {
                color: $text-color;
            }

            &:hover,
            &:focus {
                > a {
                    color: $color-blue-light;
                }
            }

            &.active {
                > a {
                    color: $color-blue-light;
                }
            }

            &.has-subnav {
                > .toggle-subnav {
                    @include icon(arrow, $text-color, 15px, 14px);
                }

                &:hover,
                &:focus {
                    > .toggle-subnav {
                        @include icon(arrow, $color-blue-light, 15px, 14px);
                    }
                }

                &.active {
                    > .toggle-subnav {
                        @include icon(arrow, $color-blue-light, 15px, 14px);
                    }
                }

                // scss-lint:disable MergeableSelector
                &.active.subnav-active {
                    > .toggle-subnav {
                        @include icon(arrow, $color-blue-light, 14px, 14px);
                    }
                }
                // scss-lint:enable MergeableSelector
            }
        }
    }

    &--header {
        @include media-breakpoint-down(md) {
            background-color: $color-white;
        }

        ul {
            li {
                a {
                    color: $text-color;
                    text-transform: none;
                }

                > ul {
                    background-color: $color-white;
                }

                &:hover,
                &:focus {
                    > a {
                        color: $color-blue-light;
                    }
                }

                &.has-subnav {
                    &:hover,
                    &:focus {
                        > a {
                            &::after {
                                border-top-color: $color-white;
                            }
                        }
                    }

                    &.active,
                    &.subnav-active {
                        > a {
                            &::after {
                                @include icon(close, $text-color, 8px, 8px);
                            }
                        }
                    }
                }
            }
        }
    }

    &--subnav {
        background-color: $color-blue-light;

        @include media-breakpoint-down(md) {
            background-color: transparent;
        }

        #{ $self }__highlight {
            background: $color-white;

            &::after {
                background: $color-black;
            }
        }

        ul {
            li {
                a {
                    color: $color-white;

                    @include media-breakpoint-down(md) {
                        color: $color-blue-dark;
                    }
                }

                &:hover,
                &:focus {
                    > a {
                        color: $color-blue-dark;

                        @include media-breakpoint-down(md) {
                            color: $color-blue-light;
                        }
                    }
                }
            }
        }
    }

    &--footer {
        ul {
            li {
                a {
                    color: $color-white;
                    font-size: 16px;
                    text-transform: none;

                    &:hover,
                    &:focus {
                        color: $color-blue-light;
                    }
                }
            }
        }
    }

    &--anchor {
        ul {
            li {
                a {
                    color: $color-white;

                    &::before {
                        background: $color-white;
                    }
                }

                &:hover,
                &:focus {
                    > a {
                        color: $color-white;
                    }
                }

                &.active {
                    > a {
                        color: $color-white;
                    }
                }
            }
        }
    }
}
