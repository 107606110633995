// scss-lint:disable ImportantRule
.block {
    &--color-blue {
        background: $color-blue-light;
    }

    &--color-blue-dark {
        background: $color-blue-dark;
    }

    &--color-black {
        background: $color-black;
    }
}
// scss-lint:enable ImportantRule
