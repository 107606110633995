.block {
    &--jobs {
        margin-bottom: $default-block-margin;

        h1 {
            color: #1c3775;
        }
    }
}

.jobs {
    &__content {
        @include make-col(12);
    }

    &__form {
        .form {
            flex-direction: row;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
             @include media-breakpoint-down(sm) {
              flex-direction: column;
            }
        }
    
        .form-group {
            @include make-col(2.4);
            padding: 0 10px;

            @include media-breakpoint-down(lg) {
                @include make-col(6);
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
                padding: 0;
            }

            &:last-of-type {
                padding-left: 30px;
                padding-right: 0;

                @include media-breakpoint-down(lg) {
                    @include make-col(6);
                }

                @include media-breakpoint-down(md) {
                    @include make-col(12);
                    padding: 0;
                }
            }

            .btn {
                width: 100%;
            }
        }

        .form-control {
            padding: .8rem 0;
        }
    }
}

.jobs-overview {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2px;
    grid-auto-rows: 1fr;

    @include media-breakpoint-down(sm) {
        grid-auto-rows: 0fr;
    }

    &__item {
        display: grid;
        grid-template-columns: 50% 1fr 12% 20%;
        background: $color-blue-light;
        color: $white;
        padding: 10px;
        grid-gap: 10px;

        @include media-breakpoint-down(md) {
            grid-template-columns: 60% 1fr;
        }

        @include media-breakpoint-down(sm) {
            grid-template-columns: 1fr;
            grid-gap: 0;
        }

        .is-h4 {
            color: $white;
            margin-bottom: 0;
        }

        .btn {
            &--link {
                color: $white;

                &:hover,
                &:focus {
                    color: $white;
                    text-decoration: underline;
                }
            }
        }

        .column {
            display: grid;
            align-items: center;

            &:empty {
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
        }

        &:focus,
        &:hover {
            background-color: $color-blue-dark;
            color: $white;
        }
    }

    &__empty {
        background: $color-blue-light;
        color: $white;
        padding: 15px;

        .is-h2 {
            color: $white;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }
}


.home {
    .jobs {
        &__form {
            .form  {
                width: unset;
            }
        }
    }
}