.ea-job-nl {
    body {
        background-image: url('/assets/img/sprays/spray-side-left.png'), url('/assets/img/sprays/spray-side-right.png');
        background-position: left 20%, right 85%;
        background-repeat: no-repeat, no-repeat;
    }    
}


.spray {
    position: absolute;
    background-image: url('/assets/img/sales/spray.svg');
    background-repeat: no-repeat;
    width: 80px;
    height: 600px;
    z-index: 1000;

    &.top {
        top: 0px;
        right: 0px;
    }

    &.bottom {
        bottom: 40%;
        left: 0px;    
        rotate: 180deg;
    }
}

.rocket {
    position: fixed;
    left: -135px;
    top: 45%;
    background-image: url(/assets/img/sales/rocket.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 400px;
    height: 400px;
    z-index: 999;

    @include media-breakpoint-down(sm) {
        width: 150px;
        height: 150px;
        left: -45px;
        bottom: 0px;
        top: unset;
    }
}


// .block--hero {
//     &::before {
//         content: "";
//         width: 240px;
//         height: 200px;
//         background-image: url('/assets/img/sprays/spray-heading-2.png');
//         position: absolute;
//         bottom: -110px;
//         right: 10vw;
//         background-repeat: no-repeat;
//         background-size: contain;
//         z-index: 0;
//     }
// }

// .job__sidebar {
//     position: relative;

//     &::before {
//         content: "";
//         width: 240px;
//         height: 200px;
//         background-image: url('/assets/img/sprays/spray-heading-4.png');
//         position: absolute;
//         top: 35vw;
//         right: 10vw;
//         background-repeat: no-repeat;
//         background-size: contain;
//         z-index: -1;
//     }
// }


// .prefooter {
//     position: relative;

//     &::before {
//         content: "";
//         width: 240px;
//         height: 200px;
//         background-image: url('/assets/img/sprays/spray-heading-1.png');
//         position: absolute;
//         bottom: 0;
//         left: 20vw;
//         background-repeat: no-repeat;
//         background-size: contain;
//         z-index: 0;
//     }

//     .prefooter__bottom {
//         position: relative;
//         z-index: 1;
//     }
// }