// scss-lint:disable ImportantRule
.multiselect-native-select {
    .dropdown-menu {
        height: 200px;
        overflow-y: scroll;
    }

    .btn-group {
        @extend .form-control;
        padding: 0 !important;
        position: relative;

        .multiselect {
            color: $color-blue-dark;
            width: 100%;
            text-align: left;
            height: 100%;
            padding: .8rem 0;
        }
    }
}
// scss-lint:enable ImportantRule
