// Hero block

.block {
    &--hero {
        margin: 0;

        &:first-child {
            margin-top: -30px;
            margin-bottom: 30px;
        }


        .jobs__form {
            position: absolute;
            display: flex;
            max-width: 100%;
            width: 960px;
            top: 60%;
            left: 0; 
            right: 0; 
            margin: 0 auto;
            flex-direction: row;
            background: $color-white;
            border-radius: 30px;
            overflow: hidden;

            @include media-breakpoint-down(sm) {
                position: relative;
                left: 0;
                top: auto;
                border-radius: 0;
                flex-direction: column;
            }

            .form {
                margin: 0;
                padding: 0 30px;
                border-right: 1px solid $color-gray-light;
        
                &-group {
                    margin: 0;
                    flex: 0 0 100%;
                    max-width: 100%;
                    
                    input {
                        border-bottom: 0;

                        @include media-breakpoint-down(sm) {
                            text-align: center;
                        }
                    }

                    &:last-of-type {
                        padding: 0;
                    }

                    .submit {
                        position: absolute;
                        right: -20px;
                        font-size: 0;
                        width: 30px;
                        top: 12px;
                        background: none;
                        border: none;
                        @include icon(search, $color-blue-dark, 20px, 20px);
                    }
                }
            }

            a {
                flex: 1;
                flex-basis: fit-content;
                text-align: center;
                padding: 13px 20px;
                color: $text-color;
                font-weight: bold;

                &:last-of-type {
                    color: $color-white;
                    font-weight: normal;
                    background-color: $color-blue-light;
                }
            }
        }
    }
}

.hero {
    margin: 0;

    &__content {
        .is-h1 {
            color: $color-white;
            font-size: 56px;
            line-height: 56px;

            @include media-breakpoint-down(md) {
                font-size: 48px;
                line-height: 48px;
            }

            @include media-breakpoint-down(sm) {
                font-size: 38px;
                line-height: 38px;
            }
        }
    }
}

.home {
    .block {
        &--hero {
            .wrapper {
                padding: 0;
                max-width: none;
            }
        }
    }
}

.ea-job-nl {
    .hero {
        &__content {
            .is-h1 {
                font-weight: 500;
            }
        }
    }
}