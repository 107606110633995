.block {
    &--job {
        margin-bottom: $default-block-margin;
    }
}

.job {
    @include make-row;

    &__content {
        @include make-col(8);
        padding: 0 15px;

        .is-h2 {
            color: $color-blue-dark;
        }

        @include media-breakpoint-down(lg) {
            .actions {
                .btn {
                    &--link {
                        margin-top: 15px;
                    }
                }
            }
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        li {
            list-style-image: url(/assets/img/vinkjegroen.svg);
        }
    }

    .btn--social-share {
        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    &__sidebar {
        @include make-col(4);
        padding: 0 15px;
        
        &--buttons {
            @include media-breakpoint-up(md) {
                position: fixed;
                bottom: 3%;

                .btn {
                    margin-bttom: 10px;

                    &--social-share {
                        display: block;
                    }
                }
            }
        }

        @include media-breakpoint-down(lg) {
            .btn {
                font-size: 14px;
            }
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        .btn {
            margin-bottom: $default-margin;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        .is-h4 {
            margin-bottom: 5px;
        }

        .faq__content {
            padding: 0;
            flex: 0 0 100%;
            max-width: 100%;

            .faq-question__content {
                padding: 0;

                .richtext {
                    font-size: 16px;
                }
            }
        }
    }

    &__card {
        background-color: white;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.220);

        padding: 4%;
        margin-top: 12px;
    }

    &__title {
        font-size: 27px;
        color: #1c3775;
        margin-bottom: 5px;
    }

    &__info {
        display: flex;

        @include media-breakpoint-down(sm) {
            display: block;
        }

        .educationlevel {
            position: relative;
            margin-left: 45px !important;

            &:after {
                content: "";
                background: url(/assets/img/education.svg);
                position: absolute;
                height: 80px;
                width: 80px;
                left: -54px;
                top: -22px;
                background-repeat: no-repeat;
                transform: scale(0.4);
                @include media-breakpoint-down(sm) {
                    top: -27px;
                }
            }
        }

        .contracttime {
            position: relative;
            margin-left: 40px !important;
            
            &:after {
                content: "";
                background: url(/assets/img/briefcase.svg);
                position: absolute;
                height: 80px;
                width: 80px;
                left: -51px;
                top: -19px;
                background-repeat: no-repeat;
                transform: scale(0.4);
                @include media-breakpoint-down(sm) {
                    top: -24px;
                }
            }
        }

        .hours {
            position: relative;
            margin-left: 40px !important;

            &:after {
                content: "";
                background: url(/assets/img/clock.svg);
                position: absolute;
                height: 80px;
                width: 80px;
                left: -55px;
                top: -23px;
                background-repeat: no-repeat;
                transform: scale(0.3);
                @include media-breakpoint-down(sm) {
                    top: -28px;
                }
            }
        }

        .functiontype {
            position: relative;
            margin-left: 25px !important;

            &:before {
                content: "";
                background: url(/assets/img/people.svg);
                position: absolute;
                height: 80px;
                width: 80px;
                left: -54px;
                top: -23px;
                background-repeat: no-repeat;
                transform: scale(0.3);
                @include media-breakpoint-down(sm) {
                    top: -28px;
                }
            }
        }

        .salary {
            position: relative;
            margin-left: 25px !important;

            &:before {
                content: "";
                background: url(/assets/img/salary.svg);
                background-size: 58px;
                position: absolute;
                height: 80px;
                width: 88px;
                left: -54px;
                top: -22px;
                background-repeat: no-repeat;
                transform: scale(0.3);
                @include media-breakpoint-down(sm) {
                    top: -27px;
                }
            }
        }

        .location {
            position: relative;
            padding-left: 25px !important;

            &:before {
                content: "";
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
                height: 24px;
                width: 24px;
                @include icon(marker-alt, $color-blue-light, 24px, 24px);
            }
        }

        .business {
            position: relative;
            padding-left: 27px !important;

            &:before {
                content: "";
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
                height: 24px;
                width: 24px;
                @include icon(business, $color-blue-light, 20px, 20px);
            }
        }

        span {
            font-size: 15px;
            margin-left: 24px;
            &:nth-of-type(1) {
                margin-left: 0;
            }
        }
    }

    &__summary {
        font-size: 16px;
        margin-top: 5px;
    }

    &__vacancybtn {
        border-radius: 40px;
        background: transparent;
        color: #1c3775;
        border-color: #1c3775;
        font-weight: 400;
        text-transform: unset;

        padding: 11px 27px;
        font-size: 20px;
    }

    .social-share {
        margin-bottom: 20px;

        .btn {
            &--social-share {
                background-color: #cccccc;
                padding: 8px 16px;
                font-size: 16px;
                border-radius: 30px;

                &:hover {
                    color: #000;
                }
            }
        }

        &--wrapper {
            position: fixed;
            right: 30px;;
        }
    }

    &__info {
        .social-share {
            margin-left: auto;
            margin-top: -10px;

            @include media-breakpoint-down(sm) {
                margin-top: 10px;
                width:100%;
            }
        }
    }


}
