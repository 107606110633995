.list {
    &--vcard {
        margin: 0 -20px;

        li {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            border-bottom: 1px solid $color-gray-3;
            padding: 12px 20px;

            a {
                flex: 0 0 100%;
                max-width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
            }

            span {
                font-size: 16px;
                line-height: 22px;
                color: $color-blue-dark;
            }

            .icon {
                width: 19px;
                height: 23px;
                flex: 0 0 19px;
                max-width: 19px;
                background-position: left center;
                background-size: 15px;

                + span {
                    margin-left: auto;
                    flex: 0 0 91%;
                    max-width: 91%;
                }

                &--globe {
                    @include icon(globe, $color-blue-light, 15px, 15px);
                }
            }
        }
    }

    &--usps {
        justify-content: space-evenly;
    }

    &--naw {
        li {
            a,
            button {
                color: $color-blue-dark;

                .icon {
                    &--phone {
                        @include icon(phone, $color-blue-dark, 30x, 30px);
                    }

                    &--messenger {
                        @include icon(messenger, $color-blue-dark, 30px, 30px);
                    }

                    &--form {
                        @include icon(form, $color-blue-dark, 30px, 30px);
                    }

                    &--marker {
                        @include icon(marker, $color-blue-dark, 38px, 38px);
                    }

                    &--chat {
                        @include icon(chat, $color-blue-dark, 30px, 30px);
                    }

                    &--globe {
                        @include icon(globe-small, $color-blue-dark, 30px, 30px);
                    }
                }

                &:hover,
                &:focus {
                    color: $color-blue-light;

                    .icon {
                        &--phone {
                            @include icon(phone, $color-blue-light, 30px, 30px);
                        }

                        &--messenger {
                            @include icon(messenger, $color-blue-light, 30px, 30px);
                        }

                        &--form {
                            @include icon(form, $color-blue-light, 30px, 30px);
                        }

                        &--marker {
                            @include icon(marker, $color-blue-light, 38px, 38px);
                        }

                        &--chat {
                            @include icon(chat, $color-blue-light, 30px, 30px);
                        }

                        &--globe {
                            @include icon(globe-small, $color-blue-light, 30px, 30px);
                        }
                    }
                }
            }
        }
    }
}
