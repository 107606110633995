// How to block

.howto {
    &__content {
        padding: 20px 10px 0;
        color: $color-white;
        font-weight: 700;
        background: $color-blue-dark;
    }
}
