html,
body {
    font-family: $text-font;
    color: $text-color;
    font-weight: 400;
    font-size: $text-size;
    line-height: $line-height;
    background: $color-white;
    letter-spacing: 0;
}

::-moz-selection {
    background-color: $color-blue-light;
    color: $color-white;
}

::selection {
    background-color: $color-blue-light;
    color: $color-white;
}

.search-highlight {
    background-color: $color-blue-light;
    color: $color-white;
}

a,
.link {
    color: $color-blue-light;

    &:hover,
    &:focus {
        color: $color-blue-dark;
    }
}

a[href^="tel"] {
    color: inherit;
    text-decoration: none;
}

.date-label {
    background: $color-black;
    color: $color-white;
}

.is-video {
    &::before {
        @include icon(play, $color-white, 50px, 50px);
        background-color: $color-blue-light;
    }

    &:hover,
    &:focus {
        &::before {
            @include icon(play, $color-white, 50px, 50px);
            background-color: $color-blue-dark;
        }
    }
}

.icon {
    display: inline-block;
    height: 40px;
    width: 40px;

    &--check {
        @include icon(check, $color-white, 40px, 40px);
    }

    &--phone {
        @include icon(phone, $color-white, 17px, 30px);
    }

    &--messenger {
        @include icon(messenger, $color-white, 30px, 31px);
    }

    &--form {
        @include icon(form, $color-white, 30px, 17px);
    }

    &--marker {
        @include icon(marker, $color-white, 27px, 39px);
    }

    &--chat {
        @include icon(chat, $color-white, 30px, 31px);
    }

    &--instagram {
        @include icon(instagram, $color-white, 18px, 18px);
    }

    &--linkedin {
        @include icon(linkedin, $color-white, 18px, 18px);
    }

    &--email {
        @include icon(email, $color-white, 18px, 18px);
    }

    &--globe {
        @include icon(globe, $color-white, 30px, 30px);
    }

    &--brand-site {
        @include icon(brand-site, $color-white, 37px, 17px);
        height: 50px;
    }

    &--flag {
        @include icon(flag, $color-blue-light, 14px, 20px);
        width: 14px;
        height: 20px;
    }

    &--mailbox {
        @include icon(mailbox, $color-blue-light, 18px, 13px);
        width: 18px;
        height: 13px;
    }

    &--marker-alt {
        @include icon(marker-alt, $color-blue-light, 14px, 21px);
        width: 14px;
        height: 21px;
    }

    &--mail-alt {
        @include icon(mail-alt, $color-blue-light, 19px, 13px);
        width: 19px;
        height: 13px;
    }

    &--phone-alt {
        @include icon(phone-alt, $color-blue-light, 18px, 17px);
        width: 18px;
        height: 17px;
    }

    &--mobile {
        @include icon(mobile, $color-blue-light, 15px, 22px);
        width: 16px;
        height: 25px;
    }

    &--save {
        @include icon(save, $color-white, 22px, 22px);
        width: 22px;
        height: 22px;
    }

    &--share {
        @include icon(share, $color-white, 21px, 21px);
        width: 21px;
        height: 21px;
    }
}

.underline {
    text-decoration: underline;
}

section {
    &.main {
        position: relative;
        flex: 1 0 auto;
        margin: 0;

        &::before {
            display: none;
        }

        &::after {
            display: none;
        }
    }
}

.info-toggle {
    color: $color-black-off;
    background: $color-white;
}

.modal {
    background: rgba($color-blue-dark, .9);

    .link,
    a {
        color: $color-white;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    .btn {
        &--close {
            &:hover,
            &:focus {
                color: $color-black;

                &::after {
                    @include icon(close, $color-black, 20px, 20px);
                }
            }
        }
    }
}
