.prefooter {
    &__bottom {
        background-color: wite;
        text-align: center;
        margin: 40px 0;

        .is-h2 {
            color: #1c3775;
            font-weight: 400;

            span {
                font-weight: 900;
            }
        }
    }
}

.footer {
    color: $color-white;
    background-color: $color-blue-dark;
    padding-top: 32px;

    .list {
        &--social-follow {
            li {
                &.facebook {
                    a {
                        &::after {
                            @include icon(facebook, $color-blue-light, 20px, 20px);
                        }

                        &:hover,
                        &:focus {
                            background-color: transparent;

                            &::after {
                                @include icon(facebook, $color-white, 20px, 20px);
                            }
                        }
                    }
                }

                &.twitter {
                    a {
                        &::after {
                            @include icon(twitter, $color-blue-light, 20px, 20px);
                        }

                        &:hover,
                        &:focus {
                            background-color: transparent;

                            &::after {
                                @include icon(twitter, $color-white, 20px, 20px);
                            }
                        }
                    }
                }

                &.youtube {
                    a {
                        &::after {
                            @include icon(youtube, $color-blue-light, 20px, 20px);
                        }

                        &:hover,
                        &:focus {
                            background-color: transparent;

                            &::after {
                                @include icon(youtube, $color-white, 20px, 20px);
                            }
                        }
                    }
                }

                &.linkedin {
                    a {
                        &::after {
                            @include icon(linkedin, $color-blue-light, 20px, 20px);
                        }

                        &:hover,
                        &:focus {
                            background-color: transparent;

                            &::after {
                                @include icon(linkedin, $color-white, 20px, 20px);
                            }
                        }
                    }
                }

                &.instagram {
                    a {
                        &::after {
                            @include icon(instagram, $color-blue-light, 20px, 20px);
                        }

                        &:hover,
                        &:focus {
                            background-color: transparent;

                            &::after {
                                @include icon(instagram, $color-white, 20px, 20px);
                            }
                        }
                    }
                }

                &.pinterest {
                    a {
                        &::after {
                            @include icon(pinterest, $color-blue-light, 20px, 20px);
                        }

                        &:hover,
                        &:focus {
                            background-color: transparent;

                            &::after {
                                @include icon(pinterest, $color-white, 20px, 20px);
                            }
                        }
                    }
                }
            }
        }
    }

    .quick_links {
        font-weight: 400;
        font-size: 14px;

        a {
            color: white;
        }
    }

    &__top {
        display: flex;
        flex-wrap: wrap;

        margin-top: 50px;
    }

    .container {
        @include media-breakpoint-down(lg) {
            text-align: center;
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__item {
        position: relative;
        flex: 0 0 25%;
        max-width: 25%;

        @include media-breakpoint-down(lg) {
            flex: 0 0 100%;
            max-width: 100%;

            &:nth-of-type(2) {
                margin-top: 25px;
            }
        }

        .list {
            &--social-follow {
                position: absolute;
                
                @include media-breakpoint-down(sm) { 
                    position: relative;
                }
                
                bottom: 0;

                li {
                    font-weight: bold;
                    line-height: 36px;
                    
                    @include media-breakpoint-down(sm) { 
                        line-height: 18px;
                    }
                    
                    max-width: 35px;
                    margin: 0;

                    &:first-of-type {
                        max-width: 70px;

                        @include media-breakpoint-down(sm) { 
                            flex: 0;
                        }
                    }

                    &.facebook {
                        a {
                            &::after {
                                @include icon(facebook, $color-white, 20px, 20px);
                            }
    
                            &:hover,
                            &:focus {
                                background-color: transparent;
    
                                &::after {
                                    @include icon(facebook, $color-white, 20px, 20px);
                                }
                            }
                        }
                    }
    
                    &.twitter {
                        a {
                            &::after {
                                @include icon(twitter, $color-white, 20px, 20px);
                            }
    
                            &:hover,
                            &:focus {
                                background-color: transparent;
    
                                &::after {
                                    @include icon(twitter, $color-white, 20px, 20px);
                                }
                            }
                        }
                    }
    
                    &.youtube {
                        a {
                            &::after {
                                @include icon(youtube, $color-white, 20px, 20px);
                            }
    
                            &:hover,
                            &:focus {
                                background-color: transparent;
    
                                &::after {
                                    @include icon(youtube, $color-white, 20px, 20px);
                                }
                            }
                        }
                    }
    
                    &.linkedin {
                        a {
                            &::after {
                                @include icon(linkedin, $color-white, 20px, 20px);
                            }
    
                            &:hover,
                            &:focus {
                                background-color: transparent;
    
                                &::after {
                                    @include icon(linkedin, $color-white, 20px, 20px);
                                }
                            }
                        }
                    }
    
                    &.instagram {
                        a {
                            &::after {
                                @include icon(instagram, $color-white, 20px, 20px);
                            }
    
                            &:hover,
                            &:focus {
                                background-color: transparent;
    
                                &::after {
                                    @include icon(instagram, $color-white, 20px, 20px);
                                }
                            }
                        }
                    }
    
                    &.pinterest {
                        a {
                            &::after {
                                @include icon(pinterest, $color-white, 20px, 20px);
                            }
    
                            &:hover,
                            &:focus {
                                background-color: transparent;
    
                                &::after {
                                    @include icon(pinterest, $color-white, 20px, 20px);
                                }
                            }
                        }
                    }
                }
            }
        }

        p, a {
            margin-bottom: 0;
            font-size: 16px;
            position: relative;
        }   
        
        &:nth-of-type(2) {
            display: flex;
            flex-direction: column;

            .nav-item {
                text-decoration: none;
                color: white;
                padding: 0 0 0 20px;

                &:after {
                    @include media-breakpoint-up(lg) {
                        content: "";
                    }
                    background: url(/assets/img/arrow-right-blue.svg);
                    background-position: center;
                    position: absolute;
                    height: 28px;
                    width: 20px;
                    left: 0px;
                    background-repeat: no-repeat;
                    transform: scale(0.6);
                }
            }
        }
    }

    &__bottom {
        border-top: 0.1px solid #b8b9ba;
        margin-top: 30px;
        padding-top: 30px;
        padding-bottom: 50px;
        display: flex;

        @include media-breakpoint-down(lg) {
            flex-direction: column;
            text-align: center;
        }

        .mainsite_link {
            float: right;
            color: white;
            text-decoration: none;
            position: relative;
            padding-right: 20px;
            margin-left: auto;
            @include media-breakpoint-down(lg) {
                margin-right: auto;
            }

            &:after {
                content: "";
                background: url(/assets/img/arrow-right-white.svg);
                position: absolute;
                height: 65px;
                width: 20px;
                left: 257px;
                top: -5px;
                background-repeat: no-repeat;
                transform: scale(0.6);
            }
        }
    }
}
